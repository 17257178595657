import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import { forEach } from 'lodash'

import { Layout } from '../components'
import { Intro } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon, filterItem } from '../styles/global'
import { media } from '../styles/utils'
import { heading1, heading2, subheading2 } from '../styles/type'
import { brown1, brown3, brown5 } from '../styles/colors'
import downArrow from '../assets/images/icons/down-arrow.svg'

const Team = props => {
    const graphQuery = useStaticQuery(query);
    const graphData = graphQuery.allWordpressInfopages.nodes[0]
    const teamData = graphQuery.allWordpressTeamOrdered.nodes
    const data = JSON.parse(graphData.acf_json)
    const [activeRole, setActiveRole] = useState(false)    

    const handleFilterChange = (slug) => {
        if (activeRole == slug) {
            return setActiveRole(false)
        }
        
        setActiveRole(slug)
    }

    const renderFilters = () => {
        const roles = data.roles.map((item, i) => {
            return (
                <FilterItem 
                    key={i}
                    active={activeRole == item.slug}
                    onClick={() => handleFilterChange(item.slug)}
                    dangerouslySetInnerHTML={{__html: item.title}}
                />
            )
        })

        return (
            <Filters>
                <FiltersContainer>
                    <Heading>Sort</Heading>
                    <Subheading>
                        Type
                        <Icon src={downArrow}/>
                    </Subheading>
                    <FilterItem 
                        key={'all'}
                        active={false}
                        onClick={() => handleFilterChange(false)}
                        dangerouslySetInnerHTML={{__html: 'All'}}
                    />
                    {roles}
                </FiltersContainer>
            </Filters>
        )
    }

    const renderTeam = () => {
        return teamData.map((term, i) => {
            return term.team.map((item, i) => {
                item.acf = JSON.parse(item.acf_json);
                const image = item.acf.headshot
                const first_name = item.acf.first_name
                const last_name = item.acf.last_name
                const rolesOverride = item.acf.role_override
    
                // Roles
    
                const roles = item.acf.roles.map((item, i) => {
                    return (
                        <Role 
                            key={i}
                        >
                            {i == 1 && `and `}
                            {item.name}
                        </Role>
                    )
                })
    
                // Filtering
                
                if (activeRole) {
                    let match = false;
    
                    forEach(item.acf.roles, (item) => {
                        if (item.slug == activeRole) match = true
                    })
    
                    if (!match) return
                }
    
                return (
                    <TeamLink key={i} to={`/team/${item.slug}`}>
                        <Transition 
                            key={image.ID} 
                            whileHover={'hover'}
                        >
                            <ImageWrapper>
                                <BGImage
                                    image={image.sizes && image.sizes.medium2}
                                    key={image.ID}
                                />
                            </ImageWrapper>
    
                            <TeamDescription
                                key={image.ID}
                                {...animatedDescription}
                            >
                                <Roles>{roles}</Roles>
    
                                <FirstName
                                    key={image.first_name}
                                    {...animatedLink}
                                    dangerouslySetInnerHTML={{
                                        __html: first_name,
                                    }}
                                />
    
                                <LastName
                                    key={image.last_name}
                                    {...animatedLink}
                                    dangerouslySetInnerHTML={{
                                        __html: last_name,
                                    }}
                                />
                            </TeamDescription>
                        </Transition>
                    </TeamLink>
                )
            })
        })
    }

    return (
        <Layout
            meta={data && data.seo}
            desktopHeaderTheme={'dark'}
        >
            <Intro
                heading={data.heading}
                subheading={data.subheading}
                description={data.text}
                link={data.link}
            />

            <TeamGrid>
                <Fade delay={1200}>
                    <Container>
                        <Grid>
                            {renderFilters()}
                            {renderTeam()}
                        </Grid>
                    </Container>
                </Fade>
            </TeamGrid>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Animations

const animatedImage = {
    initial: {
        scale: 1.1,
    },
    variants: {
        hover: {
            scale: 1.01,
        },
    },
    transition: { duration: 1 },
}

const animatedDescription = {
    variants: {
        hover: {
            y: '-8px',
        },
    },
    transition: { duration: 0.3 },
}

const animatedLink = {
    variants: {
        hover: {
            borderBottomColor: `${brown1}`,
        },
    },
    transition: { duration: 0.3 },
}

// Team Grid Layout

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled(motion.div)`
    background-image: url(${props => props.image});
    ${bgImage};
    height: 100%;
`

const TeamGrid = styled.div`
    width: 100%;
    padding-bottom: 180px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-bottom: 0;
        background-color: white;
    `}

    ${Grid} {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px -64px;

        ${media.phone`
            display: block;
            margin: 0;
            padding-bottom: 32px;
            border-bottom: 1px solid ${brown5};
        `}
    }
`

// Team Filters

const Icon = styled.img``
const FiltersContainer = styled.div``
const Filters = styled.div`
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;

    ${media.tablet`
        width: 33.33%;
    `}

    ${media.phone`
        width: 100%;
        height: auto;
        padding: 0;
        padding-bottom: 64px;
    `}

    ${FiltersContainer} {
        width: 190px;
        max-width: 100%;

        ${media.phone`
            width: 100%;
        `}
    }

    ${Heading} {
        padding-bottom: 8px;
        ${subheading2};
        text-transform: uppercase;
    }

    ${Subheading} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        border-top: 2px solid ${brown1};
        ${subheading2};
        margin-bottom: 4px;
    }
`

const FilterItem = styled.div`
    ${filterItem}
`

// Team Grid Items

const ImageWrapper = styled.div``
const Transition = styled(motion.div)`
    height: 100%;
`
const TeamDescription = styled(motion.div)``
const Roles = styled.div``
const Role = styled.div``
const FirstName = styled(motion.div)``
const LastName = styled(motion.div)``
const TeamLink = styled(Link)`
    position: relative;
    display: inline-block;
    width: 16.666666667%;
    margin-bottom: 64px;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;

    ${media.tabletLandscape`
        width: 25%;
    `}

    ${media.tablet`
        width: 33.33%;
    `}

    ${media.phone`
        width: 100%;
        margin-bottom: 32px;
        padding: 0;
    `}

    ${ImageWrapper} {
        height: 200px;
        overflow: hidden;

        ${media.desktopSmall`
            height: 180px;
        `}

        ${media.phone`
            height: 280px;
        `}
    }

    ${TeamDescription} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        box-sizing: border-box;
        background-color: ${brown3};
        color: ${brown1};

        ${media.tablet`
            display: block;
        `}

        ${media.phone`
            background-color: white;
        `}
    }

    ${Roles} {
        display: flex;
        margin-top: 16px;
        
        ${Role} {
            ${subheading2};
            margin-right: 3px;
        }

        ${media.phone`
            margin-top: 12px;
        `}
    }

    ${FirstName}, ${LastName} {
        ${heading2};
        line-height: 0.75;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;

        ${media.tablet`
            display: inline-block;
            margin-bottom: 0;
        `}

        ${media.phone`
            font-size: 24px;
        `}
    }

    ${FirstName} {
        margin-top: 12px;

        ${media.tablet`
            margin-right: 4px;
        `}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "team" } }) {
            nodes {
                acf_json
            }
        }

        allWordpressTeamOrdered {
            nodes {
                term
                team {
                    slug
                    title
                    acf_json
                }
            }
        }
    }
`

export default Team
